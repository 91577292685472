import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import "./scss/styles.scss";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Lectura from './screens/Lectura';
import Main from './screens/Main';

const mdTheme = createTheme({
  palette: {
    mode: 'dark',
    backgroundColor: 'black',
    background: {
      default: 'black',
      paper: 'black',
      body: 'black',
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      backgroundColor: 'black',
      // main: '#ffbb88',
      main: '#ffeeaa',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});


function App({ ...props }) {
  const [accesoID, setAccesoID] = useState(localStorage.getItem('accesoID') ?? '');
  const successSound = new Audio('/notification.mp3');

  const handleChangeAccesoID = id => {
    setAccesoID(id);
    localStorage.setItem('accesoID', id);
  }

  return (
    <>
      <CssBaseline enableColorScheme={true} />
      <ThemeProvider theme={mdTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main {...props} accesoID={accesoID} />}>
              <Route path="home" element={<Home {...props} accesoID={accesoID} setAccesoID={handleChangeAccesoID} />} />
              <Route path="lectura" element={<Lectura {...props} accesoID={accesoID} successSound={successSound} />} />
            </Route>

          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>

  );
}

export default App;
