import { useEffect, useState } from "react";
import { useInterval } from "../../lib/useInterval";
import APIManager from '../../lib/apiManager';
import LoadingDialog from "../../components/LoadingDialog";
import { ArrowBack, ArrowForward, QrCode2 } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const { Paper, Container, Typography, Stack, Dialog, DialogTitle, DialogContent, Box } = require("@mui/material");
const { QrReader } = require("react-qr-reader")

const Lectura = ({ version, accesoID, successSound }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);
  const [data, setData] = useState(null);
  const [dataPrevia, setDataPrevia] = useState(null);

  const [side, setSide] = useState('right');

  const [esperandoResultado, setEsperandoResultado] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleQRResult = (result, error) => {
    if (!!result) {
      setData(result?.text);
    }

    if (!!error) {
      setData(null);
    }
  }

  const hacerCheckIn = (codigo, successCallback) => {
    setEsperandoResultado(true);
    APIManager.sendRequest('post', 'access', 'validateAccess', { codigo: codigo, acceso: accesoID }, response => {
      setEsperandoResultado(false);
      if (response.success) {
        if (!!successCallback) {
          successCallback(response.data);
        }
      }
    })
  }

  useEffect(() => {
    if (!accesoID) {
      navigate('/home');
    }
  }, []);

  const doBeep = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioCtx.createOscillator();
    // oscillator.type = "square";
    oscillator.type = "sine";
    oscillator.frequency.setValueAtTime(640, audioCtx.currentTime); // value in hertz
    oscillator.connect(audioCtx.destination);
    oscillator.start();
    oscillator.stop(0.1);
  }

  const accesoPermitido = (responseData) => {
    console.log('Acceso permitido');
    // const successSound = new Audio('/notification.mp3');
      successSound.play();
    setOpenSuccess(true);
    setTimeout(() => { setOpenSuccess(false); }, 3000);
  }

  const handleChangeSide = () => {
    if (side === 'left') {
      setSide('right');
    } else {
      setSide('left');
    }
  }

  useInterval(() => {
    if (!!data && data !== dataPrevia) {
      // Tenemos una nueva lectura!
      doBeep();
      hacerCheckIn(data, accesoPermitido);
      // Evitamos la repeticion
      setDataPrevia(data);
      // Habilitamos timeout para permitir check-in del mismo codigo
      setTimeout(() => { setDataPrevia(null) }, 3000);
    }
  }, 500);

  return (
    <Box className="lectura" width="100vw" onClick={handleChangeSide}>
      {!!esperandoResultado && <LoadingDialog open={true} />}
      <Dialog open={openSuccess} fullWidth={true}>
        <DialogContent sx={{backgroundColor: "green"}}>
          <Box>
            <Typography>¡Que disfrutes tu estadía!</Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" height="100%">
        {(side === 'right') &&
          <Stack className="qr--info" alignItems="center">
            <Typography color="primary" className="qr--texto">Muestre el QR</Typography>
            <Stack direction="row" alignItems="center">
              <QrCode2 className="qr--icon" color="primary" />
              <ArrowForward className="qr--arrow" color="primary" />
            </Stack>
            <Typography color="primary" className="qr--texto">dentro del cuadro</Typography>
          </Stack>}
        <Stack direction="column" alignItems="center" justifyContent="center">
          <QrReader
            className="qr-reader--scanner"
            scanDelay={500}
            onResult={handleQRResult}
          />
          <Typography color="primary" className="qr--version">{'v' + version}</Typography>
        </Stack>
        {(side === 'left') &&
          <Stack className="qr--info" alignItems="center">
            <Typography color="primary" className="qr--texto">Muestre el QR</Typography>
            <Stack direction="row" alignItems="center">
              <ArrowBack className="qr--arrow" color="primary" />
              <QrCode2 className="qr--icon" color="primary" />
            </Stack>
            <Typography color="primary" className="qr--texto">dentro del cuadro</Typography>
          </Stack>}
      </Stack>
    </Box>
  )

}

export default Lectura;